import React, { Fragment } from 'react';
import { hot } from 'react-hot-loader/root';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import DashboardPage from './pages/DashboardPage';
import AddNewProduct from './pages/AddNewProduct';
import Headerr from './components/Headerr';
import UpdatePasswordPage from './pages/UpdatePasswordPage';

const App = (props) => {
  const { userName } = props;
  return (
    <Router>
      <Fragment>
        <Headerr userName={userName} />
        <Switch>
          <Route exact path="/" component={DashboardPage} />
          <Route exact path="/inventory-item/create" component={AddNewProduct} />
          <Route exact path="/update-password" component={UpdatePasswordPage} />
        </Switch>
      </Fragment>
    </Router>
  );
};
App.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default hot(App);
