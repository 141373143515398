import React from 'react';
import 'antd/dist/antd.css';
import ProductForm from '../components/ProductFrom';

const AddNewProduct = () => {
  return (
    <div className="container" style={{ width: '40%' }}>
      <h2 className="form-heading">ADD NEW PRODUCT</h2>
      <ProductForm mode="createForm" />
    </div>
  );
};

export default AddNewProduct;
