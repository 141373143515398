import React from 'react';
import { Form, Input, DatePicker, Button, InputNumber, Radio, message, Icon, Switch } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { firebaseDb } from '../utils/firebase';

const { RangePicker } = DatePicker;

class ProductForm extends React.Component {
  handleCreateSubmit = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      console.log('valuesinadd', values);
      if (!err) {
        firebaseDb
          .collection('inventoryItems')
          .add({
            productName: values.productName,
            mrp: values.mrp,
            scheme: values.offerType === 'scheme' ? values.scheme : null,
            discount: values.offerType === 'discount' ? values.discount : null,
            startDate: values.startEndRangePicker[0].startOf('day').toDate(),
            endDate: values.startEndRangePicker[1].endOf('day').toDate(),
            isActive: true,
            createdAt: new Date(),
            offerType: values.offerType,
          })
          .then(() => {
            message.success('Product is Added Succesfully');
            form.resetFields();
          })
          .catch((error) => {
            message.error('There is some problem in Adding data');
            console.error('Error writing document: ', error);
          });
      }
    });
  };

  render() {
    const { form, mode, record } = this.props;
    console.log('mode', mode);
    console.log('record', record);
    const { getFieldDecorator, getFieldValue } = form;
    return (
      <div>
        <Form layout="horizontal" onSubmit={this.handleCreateSubmit}>
          <Form.Item label="Product Name">
            {getFieldDecorator('productName', {
              initialValue: mode === 'editForm' ? record.productName : undefined,
              rules: [
                {
                  required: true,
                  message: 'Please input Product Name',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="MRP">
            {getFieldDecorator('mrp', {
              initialValue: mode === 'editForm' ? record.mrp : undefined,
              rules: [
                {
                  required: true,
                  message: 'Please input MRP',
                },
              ],
            })(<InputNumber onChange={this.onChange} />)}
          </Form.Item>
          <Form.Item label="Offer Type">
            {getFieldDecorator('offerType', {
              initialValue: mode === 'editForm' ? record.offerType : 'discount',
              rules: [
                {
                  required: true,
                  message: 'Please input Offer',
                },
              ],
            })(
              <Radio.Group>
                <Radio value="discount">Discount</Radio>
                <Radio value="scheme">Scheme</Radio>
              </Radio.Group>,
            )}
          </Form.Item>
          {getFieldValue('offerType') === 'discount' ? (
            <Form.Item label="Discount">
              {getFieldDecorator('discount', {
                initialValue: mode === 'editForm' ? record.discount : undefined,
                rules: [
                  {
                    required: true,
                    message: 'Please input Offer Value',
                  },
                ],
              })(<InputNumber />)}
            </Form.Item>
          ) : (
            <Form.Item label="Scheme">
              {getFieldDecorator('scheme', {
                initialValue: mode === 'editForm' ? record.scheme : undefined,
                rules: [
                  {
                    required: true,
                    message: 'Please input Offer Value',
                  },
                ],
              })(<Input />)}
            </Form.Item>
          )}

          <Form.Item label="Enter start and end date">
            {getFieldDecorator('startEndRangePicker', {
              initialValue:
                mode === 'editForm'
                  ? [moment(record.startDate.toDate()), moment(record.endDate.toDate())]
                  : [moment(), undefined],
              rules: [{ type: 'array', required: true, message: 'Please select time!' }],
            })(<RangePicker />)}
          </Form.Item>
          {mode === 'createForm' ? (
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          ) : null}
          {mode === 'editForm' ? (
            <Form.Item label="Status">
              {form.getFieldDecorator('isActive', {
                initialValue: mode === 'editForm' ? record.isActive : undefined,
                rules: [
                  {
                    required: true,
                    message: 'Please enter the correct status!',
                  },
                ],
              })(
                <Switch
                  defaultChecked={record.isActive}
                  checkedChildren={<Icon type="check" />}
                  unCheckedChildren={<Icon type="close" />}
                />,
              )}
            </Form.Item>
          ) : null}
        </Form>
      </div>
    );
  }
}

ProductForm.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    getFieldValue: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
  }).isRequired,
  mode: PropTypes.string.isRequired,
  record: PropTypes.object,
};

ProductForm.defaultProps = {
  record: {},
};

export default Form.create()(ProductForm);
