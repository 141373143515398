import React, { Component } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Loader from './components/Loader';
import { firebaseAuth, uiConfig } from './utils/firebase';
import 'antd/dist/antd.css';
import App from './App';
import './style.scss';

class Root extends Component {
  state = {
    isLoggedIn: false,
    loading: true,
    userDetail: [],
  };

  unregisterAuthStateChangedObserver = null;

  componentDidMount() {
    this.unregisterAuthStateChangedObserver = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          isLoggedIn: true,
          loading: false,
          userDetail: user,
        });
      } else {
        this.setState({
          isLoggedIn: false,
          loading: false,
        });
      }
    });
  }

  componentWillUnmount() {
    this.unregisterAuthStateChangedObserver();
  }

  render() {
    const { loading, isLoggedIn, userDetail } = this.state;
    if (loading) {
      return (
        <div style={{ width: '100%', paddingTop: 150, display: 'flex', alignItem: 'center', justifyContent: 'center' }}>
          <Loader />
        </div>
      );
    }

    if (isLoggedIn === true) {
      return <App userName={userDetail.displayName} />;
    }

    return (
      <div className="root-container">
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
      </div>
    );
  }
}

export default Root;
