import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, Button, Form, message, Popconfirm, Input, Icon } from 'antd';
import '../style.scss';
import { firebaseDb } from '../utils/firebase';
import EditProduct from '../components/EditProduct';

const { Column } = Table;
const ButtonGroup = Button.Group;

async function asyncCall(querySnapshot) {
  const promise = await new Promise((resolve) => {
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });
    resolve(data);
  });
  return promise;
}

class DashboardPage extends Component {
  state = {
    loading: true,
    tableData: [],
  };

  unsubscribeInventoryItemsListener = null;

  componentDidMount() {
    this.unsubscribeInventoryItemsListener = firebaseDb.collection('inventoryItems').onSnapshot((querySnapshot) => {
      asyncCall(querySnapshot).then((res) => {
        this.setState({ loading: false, tableData: res });
      });
    });
  }

  componentWillUnmount() {
    this.unsubscribeInventoryItemsListener();
  }

  handleDelete = (recordId) => {
    firebaseDb
      .collection('inventoryItems')
      .doc(recordId)
      .delete()
      .then(function() {
        message.success('Document successfully deleted!');
      })
      .catch(function(error) {
        message.error('Error in deleting the item!!');
        console.error('Error removing document: ', error);
      });
  };

  cancel = (e) => {
    console.log('cancle', e);
    message.error('Product is not deleted');
  };

  render() {
    const { loading, tableData } = this.state;
    console.log('tabledata', tableData);
    return (
      <div className="container">
        <h1 className="header-container">Product list of Samdani Pharma</h1>
        <div>
          <Table dataSource={tableData} bordered pagination={false} loading={loading} rowKey="id">
            <Column
              title="Product Name"
              dataIndex="productName"
              key="productName"
              render={(text) => <div>{text}</div>}
              onFilter={(value, record) =>
                record.productName
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase())
              }
              filterIcon={(filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />}
              filterDropdown={({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                  <Input
                    ref={(node) => {
                      this.searchInput = node;
                    }}
                    placeholder="Search Product Name"
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => confirm()}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                  />
                  <Button
                    type="primary"
                    onClick={() => confirm()}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                  >
                    Search
                  </Button>
                  <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                    Reset
                  </Button>
                </div>
              )}
            />
            <Column
              title="MRP"
              dataIndex="mrp"
              key="mrp"
              align="right"
              render={(text, record) => {
                const mrpOfProduct = record.mrp;
                return mrpOfProduct.toLocaleString('en-IN', {
                  style: 'currency',
                  currency: 'INR',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                });
              }}
            />
            <Column
              title="Offer"
              dataIndex="offer"
              key="offer"
              render={(text, record) => {
                const discountOfProduct = record.discount;
                const schemeOfProduct = record.scheme;
                return discountOfProduct === null ? (
                  <div>
                    <h5 style={{ fontWeight: '700' }}>Scheme:</h5>
                    <span>{schemeOfProduct}</span>
                  </div>
                ) : (
                  <div>
                    <h5 style={{ fontWeight: '700' }}>Discount:</h5>
                    <span>{`${discountOfProduct}%`}</span>
                  </div>
                );
              }}
            />
            <Column
              title="Start Date"
              dataIndex="startdate"
              key="startDate"
              render={(text, record) => {
                const { startDate } = record;

                return moment(startDate.toDate()).format('ll');
              }}
            />
            <Column
              title="End Date"
              dataIndex="endDate"
              key="endDate"
              render={(text, record) => {
                const { endDate } = record;

                return moment(endDate.toDate()).format('ll');
              }}
            />
            <Column
              title="Status"
              dataIndex="isActive"
              key="isActive"
              filters={[{ text: 'Active', value: true }, { text: 'Inactive', value: false }]}
              onFilter={(value, record) => record.isActive === value}
              render={(text, record) => {
                const status = record.isActive ? 'Active' : 'Inactive';
                return status;
              }}
            />
            <Column
              title="Action"
              key="action"
              render={(text, record) => {
                return (
                  <span>
                    <ButtonGroup>
                      <EditProduct record={record} />
                      <Popconfirm
                        title="Are you sure delete this task?"
                        onConfirm={() => this.handleDelete(record.id)}
                        onCancel={this.cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button>Delete</Button>
                      </Popconfirm>
                    </ButtonGroup>
                  </span>
                );
              }}
            />
          </Table>
        </div>
      </div>
    );
  }
}

DashboardPage.propTypes = {
  form: PropTypes.shape({
    validateFields: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form.create({ name: 'dashboardPage' })(DashboardPage);
