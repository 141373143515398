import React, { Fragment } from 'react';
import { Modal, message, Button } from 'antd';
// import moment from 'moment';
import PropTypes from 'prop-types';
import ProductFrom from './ProductFrom';
import { firebaseDb } from '../utils/firebase';

class EditProduct extends React.Component {
  state = {
    visibleModal: false,
  };

  formRef = null;

  handleClickEdit = () => {
    this.setState({ visibleModal: true });
  };

  handleCancel = () => {
    this.setState({
      visibleModal: false,
    });
  };

  handleModalOnOk = () => {
    const { form } = this.formRef.props;
    const { record } = this.props;
    form.validateFields((err, values) => {
      console.log('valuesinedit', values);
      if (!err) {
        firebaseDb
          .collection('inventoryItems')
          .doc(record.id)
          .set({
            endDate: values.startEndRangePicker[1].endOf('day').toDate(),
            startDate: values.startEndRangePicker[0].startOf('day').toDate(),
            discount: values.offerType === 'discount' ? values.discount : null,
            scheme: values.offerType === 'scheme' ? values.scheme : null,
            productName: values.productName,
            mrp: values.mrp,
            offerType: values.offerType,
            isActive: values.isActive,
          })
          .then(() => {
            message.success('Product is updated!!!!');
            this.setState({ visibleModal: false });
            form.resetFields();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  render() {
    const { visibleModal } = this.state;
    const { record } = this.props;
    return (
      <Fragment>
        <Button onClick={this.handleClickEdit}>Edit</Button>
        <Modal visible={visibleModal} title="Edit Product" onCancel={this.handleCancel} onOk={this.handleModalOnOk}>
          <ProductFrom
            mode="editForm"
            record={record}
            wrappedComponentRef={(form) => {
              this.formRef = form;
            }}
          />
        </Modal>
      </Fragment>
    );
  }
}

EditProduct.propTypes = {
  record: PropTypes.object.isRequired,
};

export default EditProduct;
