import React from 'react';
import PropTypes from 'prop-types';
import '../style.scss';
import { Layout, Menu, Dropdown, Icon } from 'antd';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { firebaseAuth } from '../utils/firebase';

const { Header } = Layout;
const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to="/update-password">Update Password</Link>
    </Menu.Item>
    <Menu.Item key="1">
      <div onClick={() => firebaseAuth.signOut()} role="button" onKeyPress={() => {}} tabIndex={0}>
        Logout
      </div>
    </Menu.Item>
  </Menu>
);

const Headerr = (props) => {
  console.log(props);
  const { history, userName, location } = props;
  const { push } = history;
  const selectedKeys = [];
  if (location.pathname === '/') selectedKeys.push('1');
  if (location.pathname === '/inventory-item/create') selectedKeys.push('2');
  return (
    <div>
      <Layout className="layout">
        <Header>
          <div className="row">
            <div className="title-name col-2" style={{ fontWeight: '600', fontSize: '20px', color: 'white' }}>
              SAMDANI PHARMA
            </div>
            <div className="tabMenu col-8">
              <Menu theme="dark" mode="horizontal" selectedKeys={selectedKeys} style={{ lineHeight: '64px' }}>
                <Menu.Item key="1" onClick={() => push('/')}>
                  View All Items
                </Menu.Item>
                <Menu.Item key="2" onClick={() => push('/inventory-item/create')}>
                  Add New Item
                </Menu.Item>
              </Menu>
            </div>
            <Dropdown className="username" overlay={menu} trigger={['click']}>
              <span className="ant-dropdown-link" style={{ cursor: 'pointer', color: 'white' }}>
                {userName}
                <Icon
                  className="ant-dropdown-link"
                  type="down"
                  style={{ margin: '5px', fontWeight: '500', cursor: 'pointer', color: 'white' }}
                />
              </span>
            </Dropdown>
          </div>
        </Header>
      </Layout>
    </div>
  );
};
// }

Headerr.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  userName: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(Headerr);
